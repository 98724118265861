<template>
  <header>
    <div>
      <img src="../assets/img/logo.jpg" alt="Лого" />
      <h1>ООО «Регард»</h1>
    </div>
    <a :href="`${publicPath}documents/regard_brochure.pdf`" target="_blank"
      >Брошюра</a
    >
  </header>
</template>

<script>
export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
};
</script>

<style scoped>
header {
  padding: 1.2rem 12%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 3.2rem;
  background-color: var(--header-bg-color);
  color: var(--text-color);
}

div {
  display: flex;
  align-items: center;
  gap: 2.4rem;
}

a:link,
a:visited {
  font-size: 2.4rem;
  color: var(--text-color);
}

a:hover,
a:active {
  color: var(--darker-text-color);
}

img {
  max-width: 14rem;
}

h1 {
  font-size: 2.4rem;
}

@media (max-width: 1024px) {
  h1 {
    font-size: 2.2rem;
  }
  img {
    max-width: 12rem;
  }
  a:link,
  a:visited {
    font-size: 2.2rem;
  }
}

@media (max-width: 768px) {
  h1 {
    font-size: 2rem;
  }
  img {
    max-width: 10rem;
  }
  a:link,
  a:visited {
    font-size: 2rem;
  }
}

@media (max-width: 425px) {
  h1 {
    font-size: 1.2rem;
  }
  img {
    max-width: 8rem;
  }
  a:link,
  a:visited {
    font-size: 1.2rem;
  }
}

@media (max-width: 375px) {
  h1 {
    font-size: 1.1rem;
  }
  img {
    max-width: 7rem;
  }
  a:link,
  a:visited {
    font-size: 1.1rem;
  }
}
</style>
