<template>
  <div class="main__content">
    <div class="main__info">
      <div class="main__info--header">
        ЗНАНИЯ И ОПЫТ В ОБЛАСТИ МОНИТОРИНГА И ОБЩЕСТРОИТЕЛЬНЫХ РАБОТ
      </div>
      <div class="certificates">
        <a :href="`${publicPath}documents/regard_smr.pdf`" target="_blank"
          ><img src="../assets/img/regard_sro.png" alt="Сертификат СРО"
        /></a>
        <a :href="`${publicPath}documents/regard_smr1.pdf`" target="_blank"
          ><img src="../assets/img/regard_sro1.png" alt="Сертификат СРО"
        /></a>
      </div>
    </div>
    <div class="contacts">
      <div class="contacts__info-text">
        <p>
          ООО «Регард» выполняет работы в сфере геодезического сопровождения
          строительства (геодезический мониторинг), а также общестроительные
          работы
        </p>
        <p>
          По вопросам сотрудничества, пишите нам на почту
          <a href="mailto:project@regard-spb.ru">project@regard-spb.ru</a>
        </p>
      </div>
      <div class="contacts__address">
        <p>
          Мы расположены по адресу: ул. Большая Зеленина, д. 24, стр. 1, офис
          129
        </p>
        <p>Санкт-Петербург</p>
        <p>197110, Россия</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
};
</script>

<style scoped>
.main__content {
  max-width: 76%;
  margin: 10rem auto;
  padding: 2.4rem 0;
  display: flex;
  gap: 15rem;
  justify-content: space-between;
  color: #fff;
}

.certificates {
  display: flex;
  gap: 3.6rem;
}

.certificates img {
  max-height: 16rem;
  transition: all 0.3s;
  padding: 0.8rem;
  background-color: #fff;
}

.certificates img:hover {
  max-height: 18rem;
}

.main__info {
  flex: 2;

  display: flex;
  flex-direction: column;
  gap: 38rem;
}

.contacts {
  flex: 1;

  display: flex;
  flex-direction: column;
  gap: 3.6rem;
}

.main__info--header {
  font-size: 3.6rem;
  text-align: center;
  line-height: 1.8;
}

.contacts__info-text {
  font-size: 1.9rem;
  line-height: 1.6;

  display: flex;
  flex-direction: column;
  gap: 3.6rem;
}

.contacts__address {
  font-size: 1.7rem;
  line-height: 1.6;
}

.contacts__info-text a:link,
.contacts__info-text a:visited,
.contacts__info-text a:hover,
.contacts__info-text a:active {
  color: #fff;
}

@media (max-width: 1440px) {
  .certificates img {
    max-height: 14rem;
  }
  .certificates img:hover {
    max-height: 16rem;
  }
  .main__info {
    gap: 34rem;
  }
  .contacts {
    gap: 2.8rem;
  }
  .main__info--header {
    font-size: 2.4rem;
  }
  .contacts__info-text {
    font-size: 1.6rem;
    gap: 1.8rem;
  }
  .contacts__address {
    font-size: 1.4rem;
  }
}

@media (max-width: 1024px) {
  .certificates img {
    max-height: 12rem;
  }
  .certificates img:hover {
    max-height: 14rem;
  }
  .main__info {
    gap: 30rem;
  }
  .contacts {
    gap: 2.8rem;
  }
  .main__info--header {
    font-size: 1.8rem;
  }
  .contacts__info-text {
    font-size: 1.2rem;
    gap: 1.6rem;
  }
  .contacts__address {
    font-size: 1.1rem;
  }
}

@media (max-width: 768px) {
  .main__content {
    gap: 6rem;
  }
  .certificates img {
    max-height: 8rem;
  }
  .certificates img:hover {
    max-height: 10rem;
  }
  .main__info {
    gap: 30rem;
  }
  .contacts {
    gap: 2.8rem;
  }
  .main__info--header {
    font-size: 1.4rem;
  }
  .contacts__info-text {
    font-size: 1.1rem;
    gap: 1.2rem;
  }
  .contacts__address {
    font-size: 1rem;
  }
}

@media (max-width: 425px) {
  .main__content {
    gap: 3rem;
    display: grid;
    grid-template-columns: 1fr;
  }
  .certificates img {
    max-height: 8rem;
    padding: 0.2rem;
  }
  .certificates img:hover {
    max-height: 10rem;
  }
  .main__info {
    gap: 10rem;
  }
}

@media (max-width: 375px) {
  .certificates img {
    max-height: 7rem;
  }
  .certificates img:hover {
    max-height: 9rem;
  }
}
</style>
